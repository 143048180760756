<template>
  <HeartysanManufacturerListingShowUser
      v-if="loaded"
      :HL="HL"
      :loadedImage="loadedImage"
  />
  <!--          @save="save({edit: true})"-->
</template>

<script>
import HeartysanManufacturerListingShowUser
  from "./HeartysanManufacturerListingShowUser/HeartysanManufacturerListingShowUser";
import {heartysanProductsMixin} from "@/mixins/heartysanMixins/heartysanProductsMixin";
import {HeartysanListing} from "@/components/modules/HeartysanModule/models/HeartysanListing";
import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";

export default {
  name: "HeartysanManufacturerListingShow",

  components: {
    HeartysanManufacturerListingShowUser,
  },

  mixins: [heartysanProductsMixin, queryFilterMixin],

  data() {
    return {
      HL: new HeartysanListing(),
      loaded: false,
      loadedImage: false,
    }
  },

  mounted() {
    this.HL.setId(this.$route.params.id)

    // initialize first product
    let myQuery = this.createQueryBuilderObject()
    let url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)
    this.$store.dispatch('fetchHeartysanCategory', url).then(response => {
      let categories = this.getRespPaginateData(response)

      this.$store.dispatch('getHeartysanProducts', this.HL.getId()).then((response) => {
        let data = this.getRespData(response)


        this.HL.setItem(data, categories)

        this.setFiles(data.files)
        this.loaded = true

      }).catch(error => this.createErrorLog(error))

    })


  },

  methods: {

    async setFiles(files) {
      if (files && files.length > 0) {
        // for await (let item of files) {
        //   await this.downloadFileFromServer(item[0]).then((dataFile) => {
        //     this.HL.setHeartysanListingDownloadFiles(dataFile)
        //   })
        // }

        let downloadFiles = []
        files.map((item, index) => {
          this.downloadFileFromServer(item[0]).then((dataFile) => {
            downloadFiles.push({index: index, dataFile: dataFile})
            this.checkDownloadedFiles(files, downloadFiles)
          })
        })
      } else {
        this.loadedImage = true
      }

    },

    checkDownloadedFiles(files, downloadFiles) {
      // console.log(files.length, downloadFiles.length);
      if(files.length === downloadFiles.length){
        let sorted = this._.sortBy(downloadFiles, ['index'])
        sorted.map(item => {
          this.HL.setHeartysanListingDownloadFiles(item.dataFile)
        })
        this.loadedImage = true
      }
    },

  }

}
</script>

<style scoped>

</style>
