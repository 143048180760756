<template>
  <div>
    <HeartysanManufacturerListingsHead
            :showFilter="showFilter"
            :navTabs="navTabs"
            :countFilterParams="countFilterParams"
            @toggleFilter="toggleFilter"
            @filter="$emit('filter')"
            @changeTab="(data) => $emit('changeTab', data)"
            @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">

      <HeartysanManufacturerListingsFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :navTabs="navTabs"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <HeartysanManufacturerListingsTable
          v-if="navTabs.all.active || navTabs.draft.active || navTabs.archive.active"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @downloadFiles="$emit('downloadFiles')"
      />

      <HeartysanManufacturerChatsTable
          v-if="navTabs.requests.active"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @downloadFiles="$emit('downloadFiles')"
      />

      <HeartysanManufacturerDealsTable
          v-if="navTabs.deals.active"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @downloadFiles="$emit('downloadFiles')"
      />
    </div>
  </div>
</template>

<script>

  import HeartysanManufacturerListingsHead from "./HeartysanManufacturerListingsHead/HeartysanManufacturerListingsHead";
  import HeartysanManufacturerListingsFilter from "./HeartysanManufacturerListingsFilter/HeartysanManufacturerListingsFilter";
  import HeartysanManufacturerListingsTable from "./HeartysanManufacturerTables/HeartysanManufacturerListingsTable/HeartysanManufacturerListingsTable";
  import HeartysanManufacturerChatsTable
    from "@/components/modules/HeartysanModule/components/HeartysanManufacturer/HeartysanManufacturerListings/HeartysanManufacturerListingsBlock/HeartysanManufacturerTables/HeartysanManufacturerChatsTable/HeartysanManufacturerChatsTable";
  import HeartysanManufacturerDealsTable
    from "@/components/modules/HeartysanModule/components/HeartysanManufacturer/HeartysanManufacturerListings/HeartysanManufacturerListingsBlock/HeartysanManufacturerTables/HeartysanManufacturerDealsTable/HeartysanManufacturerDealsTable";

  export default {
    name: "HeartysanManufacturerListingsBlock",

    components: {
      HeartysanManufacturerChatsTable,
      HeartysanManufacturerListingsHead,
      HeartysanManufacturerListingsFilter,
      HeartysanManufacturerListingsTable,
      HeartysanManufacturerDealsTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

    }
  }
</script>

<style scoped>

</style>
