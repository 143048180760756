<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          // 'ukrTreasures_AllListings',
          // 'ukrTreasures_ActiveProducts',
          // 'ukrTreasures_SoldProducts',
          // 'ukrTreasures_PayPal',
        ])"></div>
      <div class="content-tabs content-tabs--separator">
        <template v-for="(item, index) in navTabs">

          <div class="content-tabs__item"
               v-if="!(item.name === 'draft' && isAdmin) && !(item.name === 'requests' && isAdmin) && !(item.name === 'deals' && !isAdmin)"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              {{item.label}}
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton
                :count="countFilterParams"
                @toggleFilter="$emit('toggleFilter')"
                :showFilter="showFilter"
        />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">

      </div>

      <div class="btn-right-block">

        <div v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}"
             v-if="!isAdmin"
        >
          <div class="admin-edit" @click="editTranslate(['heartysan_NewListing'])"></div>
          <router-link :to="$store.getters.GET_PATHS.heartysanListingsCreate">
            <MainButton class="btn-fit-content ml-3"
                        :value="$t('heartysan_NewListing.localization_value.value')"
                        :ico="'ico'"
            >
              <template slot="ico">
                <PlusIconSVG class="btn-icon-plus"/>
              </template>
            </MainButton>
          </router-link>
        </div>
      </div>
    </div>


  </div>
</template>


<script>
  import ToggleFilterButton from "../../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../../public/img/UI-group/plus-icon.svg?inline'


  export default {
    name: "HeartysanManufacturerListingsHead",
    components: {
      ToggleFilterButton,
      MainButton,
      PlusIconSVG,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    data() {
      return {
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate() {
        console.log('changeDate function');
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../../scss/mixins/mixins";

  .light-bg-block {
    display: flex;
    flex-wrap: wrap;
  }

  @include for-992 {
    .btn-left-block {
      margin-bottom: 15px;
    }

    .btn-right-block {
      flex-wrap: wrap;
    }

    .main-button {
      margin-bottom: 10px;
    }

  }

</style>

