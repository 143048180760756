<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">
      <!--<div class="table-filter__item"-->
      <!--v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
      <!--<div class="admin-edit" @click="editTranslate(['ukrTreasures_Status'])"></div>-->
      <!--<DefaultSelect-->
      <!--:options="options"-->
      <!--:label="$t('ukrTreasures_Status.localization_value.value')"-->
      <!--/>-->
      <!--</div>-->
      <div class="table-filter__item">
        <DefaultInput
                :label="'Id'"
                :type="'text'"
                v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-if="!navTabs.deals.active && !navTabs.requests.active"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_name'])"></div>
        <DefaultInput
                :label="$t('heartysan_name.localization_value.value')"
                :type="'text'"
                v-model="name"
        />
      </div>

      <div class="table-filter__item"
           v-if="!navTabs.deals.active && !navTabs.requests.active"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_priceFrom'])"></div>
        <DefaultInput
                :label="$t('heartysan_priceFrom.localization_value.value')"
                :type="'text'"
                v-model="priceFrom"
        />
      </div>

      <div class="table-filter__item"
           v-if="!navTabs.deals.active && !navTabs.requests.active"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_priceTo'])"></div>
        <DefaultInput
                :label="$t('heartysan_priceTo.localization_value.value')"
                :type="'text'"
                v-model="priceTo"
        />
      </div>


      <div class="table-filter__item"
           v-if="isAdmin && navTabs.deals.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_manufacturer',])"></div>
<!--        <SearchSelect-->
<!--                :options="options"-->
<!--                :label="$t('heartysan_manufacturer.localization_value.value')"-->
<!--                :optionsLabel="'email.contacts[0]'"-->
<!--                :functionSearch="functionSearch"-->
<!--                :selected="userName"-->
<!--                :typeSelect="'users'"-->
<!--                :userEmail="true"-->
<!--                @change="changeUsersFilter"-->
<!--        />-->
        <DefaultSelect
            :options="options"
            :label="$t('heartysan_manufacturer.localization_value.value')"
            :otherValue="'adminItem'"
            :selected="sellerName"
            @change="changeUsersFilter"
        />
      </div>

      <div class="table-filter__item"
           v-if="navTabs.deals.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_seller',])"></div>
<!--        <SearchSelect-->
<!--                :options="options"-->
<!--                :label="$t('heartysan_seller.localization_value.value')"-->
<!--                :optionsLabel="'email.contacts[0]'"-->
<!--                :functionSearch="functionSearch"-->
<!--                :selected="sellerName"-->
<!--                :typeSelect="'users'"-->
<!--                :userEmail="true"-->
<!--                @change="changeUsersSellerFilter"-->
<!--        />-->
        <DefaultSelect
            :options="optionsSeller"
            :label="$t('heartysan_seller.localization_value.value')"
            :otherValue="'adminItem'"
            :selected="sellerName"
            @change="changeUsersSellerFilter"
        />
      </div>

      <div class="table-filter__item"
           v-if="!navTabs.deals.active && !navTabs.requests.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['heartysan_category'])"></span>
        <DefaultSelect
            :options="optionsCategory"
            :otherValue="'currentTranslation'"
            :label="$t('heartysan_category.localization_value.value')"
            @change="changeCategory"
            :selected="categoryId ? {
              id: categoryId,
              currentTranslate: {name: categoryName}
            } : null"
        />
      </div>
      <div class="table-filter__item"
           v-if="!navTabs.deals.active && !navTabs.requests.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['heartysan_subCategory'])"></span>

        <DefaultSelect
            :options="optionsSubCategory"
            :otherValue="'currentTranslation'"
            :label="$t('heartysan_subCategory.localization_value.value')"
            @change="changeSubCategory"
            :selected="subCategoryId ? {
              id: subCategoryId,
              currentTranslate: {name: subCategoryName}
            } : null"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../../mixins/filterFuncMixin/filterFunc";
  // import SearchSelect from "../../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../../mixins/usersMixins/usersFunc";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";
  import {HEARTYSAN_USER_TYPES} from "@/staticData/staticVariables";

  export default {
    name: "HeartysanManufacturerListingsFilter",
    components: {
      DefaultSelect,
      // SearchSelect,
      FilterBlock,
      MainButton,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
      navTabs: Object,
    },

    mixins: [filterFunc, usersFunc, queryFilterMixin],

    data() {
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        name: this.filterGetParams.contactName ? this.filterGetParams.contactName : '',
        status: this.filterGetParams.status ? this.filterGetParams.status : '',
        price: this.filterGetParams.price ? this.filterGetParams.price : '',
        priceFrom: this.filterGetParams.priceFrom ? this.filterGetParams.priceFrom : '',
        priceTo: this.filterGetParams.priceTo ? this.filterGetParams.priceTo : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        sellerId: this.filterGetParams.sellerId ? this.filterGetParams.sellerId : '',
        sellerName: this.filterGetParams.sellerName ? this.filterGetParams.sellerName : '',
        categoryId: this.filterGetParams.categoryId ? this.filterGetParams.categoryId : '',
        categoryName: this.filterGetParams.categoryName ? this.filterGetParams.categoryName : '',

        subCategoryId: '',
        subCategoryName: '',

        options: [],
        optionsSeller: [],

        categories: [],
        optionsCategory: [],
        optionsSubCategory: [],

        filterCounts: [
          'date',
          'id',
          'name',
          'status',
          'price',
          'priceFrom',
          'priceTo',

          'categoryId',
          'categoryName',
          'userName',
          'userId',
          'sellerId',
          'sellerName',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.id = newVal.id ? newVal.id : ''
        this.name = newVal.name ? newVal.name : ''
        this.status = newVal.status ? newVal.status : ''
        this.price = newVal.price ? newVal.price : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.sellerId = newVal.sellerId ? newVal.sellerId : ''
        this.sellerName = newVal.sellerName ? newVal.sellerName : ''
        this.categoryId = newVal.categoryId ? newVal.categoryId : ''
        this.categoryName = newVal.categoryName ? newVal.categoryName : ''
        this.priceFrom = newVal.priceFrom ? newVal.priceFrom : ''
        this.priceTo = newVal.priceTo ? newVal.priceTo : ''

        this.checkSubCategory()
      },
    },

    mounted() {
      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)
        // filterCountriesUSA(countries)
        this.optionsCountries = countries;
      })
      let myQuery = this.createQueryBuilderObject()
      let url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)
      this.$store.dispatch('fetchHeartysanCategory', url).then(response => {
        this.categories = this.getRespPaginateData(response)
        this.checkSubCategory()
      })

      myQuery = this.createQueryBuilderObject()
      myQuery.where('type_id', HEARTYSAN_USER_TYPES.HEARTYSAN_SELLER.id)
      myQuery.where('havingActiveDeals', 1)
      url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)
      this.$store.dispatch('fetchHeartysanUsers', url).then(response => {
        this.optionsSeller = this.getRespPaginateData(response)
      })

      myQuery = this.createQueryBuilderObject()
      myQuery.where('type_id', HEARTYSAN_USER_TYPES.HEARTYSAN_MANUFACTURER.id)
      myQuery.where('havingActiveDeals', 1)
      url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)
      this.$store.dispatch('fetchHeartysanUsers', url).then(response => {
        this.options = this.getRespPaginateData(response)
      })
    },

    methods: {

      checkSubCategory() {

        this.optionsCategory = this._.filter(this.categories, (item) => {
          return item.parent_id === 0
        })

        let findCategory = this._.find(this.categories, {id: parseInt(this.categoryId)})

        if(findCategory) {
          if(findCategory.parent_id !== 0){
            this.subCategoryId = this.categoryId
            this.subCategoryName = this.categoryName

            let parent = this._.find(this.categories, {id: parseInt(findCategory.parent_id)})
            if(parent){
              this.categoryId = parent.id
              this.categoryName = parent.currentTranslate.name
            }
          } else {
            this.optionsSubCategory = this._.filter(this.categories, (item) => {
              return item.parent_id === parseInt(this.categoryId)
            })
          }
        }
      },

      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        if(this.subCategoryId) {
          this.subCategoryId = ''
          this.subCategoryName = ''
        }

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')

        if(this.subCategoryId) {
          data.categoryId = this.subCategoryId
          data.categoryName = this.subCategoryName
        }

        this.$emit('changeFilter', data)
      },

      changeCategory(val) {
        this.categoryId = val.id
        this.categoryName = val.currentTranslate.name

        this.subCategoryId = ''
        this.subCategoryName = ''

        this.optionsSubCategory = this._.filter(this.categories, (item) => {
          return item.parent_id === val.id
        })
      },

      changeSubCategory(val) {
        this.subCategoryId = val.id
        this.subCategoryName = val.currentTranslate.name
      },

      changeUsersFilter(value) {
        this.userId = value.id
        if(value?.user_personal_contact?.user_full_name)
          this.userName = value.user_personal_contact.user_full_name
        else
          this.userName = ''
      },

      changeUsersSellerFilter(value) {
        this.sellerId = value.id
        if(value?.contacts && value.contacts.length > 0)
          this.sellerName = value.contacts[0].user_full_name
        else
          this.sellerName = ''
      },
    },
  }
</script>

<style scoped>

</style>
