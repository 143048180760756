<template>
  <div class="fragment">
    <HeartysanManufacturerListingsBlock
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @changeTab="changeTab"
        @resetFilter="resetFilter"
        @changeFilter="changeFilter"
        @showMore="showMore"
        @reload="reload"
    />
  </div>
</template>

<script>
import HeartysanManufacturerListingsBlock
  from "./HeartysanManufacturerListingsBlock/HeartysanManufacturerListingsBlock";
import {routeFilter} from "../../../../../../mixins/routeFilterMixins/routeFilter";
import {HEARTYSAN_STATUSES} from "@/staticData/staticVariables";
// import {radioButtonsFunc} from "../../../../../mixins/creationPageMixins/radioButtonsFunc";

export default {
  name: "HeartysanManufacturerListings",

  components: {
    HeartysanManufacturerListingsBlock,
  },

  mixins: [routeFilter],

  watch: {
    // downloadPermissions() {
    //   if(this.loadUserAuthorizedData) {
    //     this.checkTabPermission()
    //   }
    // },
    loadUserAuthorizedData() {
      // if(this.downloadPermissions) {
      //   this.checkTabPermission()
      // }
      this.filter()
    },
  },

  data() {
    return {
      forPage: this.$store.getters.getHeartysanProductsForPage,
      page: 1,
      onePage: 1,
      filterType: 'all',
      filterTypeSklad: '',
      filterDate: [],
      filterId: '',
      filterName: '',
      filterStatus: '',
      filterPrice: '',
      filterUserName: '',
      filterUserId: '',
      filterSellerName: '',
      filterSellerId: '',
      filterCategoryId: '',
      filterCategoryName: '',

      filterPriceFrom: '',
      filterPriceTo: '',

      filterCode: '',
      filterItemNumber: '',

      countFilterParams: 0,
      filterGetParams: {},

      navTabs: {
        all: {
          label: this.$t('heartysan_AllListings.localization_value.value'),
          name: 'all',
          id: 1,
          active: true,
          // permissionVisible: true,
        },
        draft: {
          label: this.$t('heartysan_DraftListings.localization_value.value'),
          name: 'draft',
          id: 2,
          active: false,
          // permissionVisible: false,
        },
        archive: {
          label: this.$t('heartysan_ArchiveListings.localization_value.value'),
          name: 'archive',
          id: 3,
          active: false,
          // permissionVisible: false,
        },
        requests: {
          label: this.$t('heartysan_requestsProducts.localization_value.value'),
          name: 'requests',
          id: 4,
          active: false,
        },
        deals: {
          label: this.$t('heartysan_requestsProducts.localization_value.value'),
          name: 'deals',
          id: 4,
          active: false,
        },

        // paypalListings: {
        //   label: this.$t('ukrTreasures_PayPal.localization_value.value'),
        //   name: 'paypalListings',
        //   id: 4,
        //   active: false,
        //   permissionVisible: true,
        // },
      },
    }
  },

  mounted() {
    console.log(3453);
    // if(this.$route.query.type !== undefined){
    //   this.navTabs.all.active = false
    //   this.navTabs[this.$route.query.type].active = true
    // }
    //
    // if(this.downloadPermissions && this.loadUserAuthorizedData){
    //   this.checkTabPermission()
    // }
    if(this.loadUserAuthorizedData) {
      this.filter()
    }
  },

  methods: {

    // checkTabPermission() {
    //   console.log(33333);
    //   this.activeTabByPermissions('navTabs', {
    //     ['activeListings']: this.PERMISSIONS.PAYMENT_LISTING_ACTIVE,
    //     ['soldListings']: this.PERMISSIONS.PAYMENT_LISTING_SOLD,
    //     ['paypalListings']: this.PERMISSIONS.PAYMENT_LISTING_PAYPAL,
    //   })
    //
    //
    //   this.hideTabAllIfVisibleOnlyOne('navTabs', 'all', ['permissionVisible'])
    //   if(!this._.find(this.navTabs, {active: true}).permissionVisible){
    //     this.setActivePermissionVisibleTab('navTabs')
    //     return
    //
    //   }
    //
    //   if (this.$store.getters.getUserProfile?.user_setting?.use_paypal_button !== 1) {
    //     this.navTabs.paypalListings.permissionVisible = false
    //   }
    //
    //   this.filter()
    // },
    //
    getFilterParam() {
      this.filterGetParams = {
        page: this.$route.query.page,
        count: this.$route.query.count,
        date: this.$route.query.date,
        type: this.$route.query.type,
        id: this.$route.query.id,
        name: this.$route.query.name,
        status: this.$route.query.status,
        price: this.$route.query.price,
        userName: this.$route.query.userName,
        userId: this.$route.query.userId,
        sellerName: this.$route.query.sellerName,
        sellerId: this.$route.query.sellerId,
        categoryId: this.$route.query.categoryId,
        categoryName: this.$route.query.categoryName,
        priceFrom: this.$route.query.priceFrom,
        priceTo: this.$route.query.priceTo,

        // code: this.$route.query.code,
        // itemNumber: this.$route.query.itemNumber,
      }
    },

    filter(next = false) {
      this.getFilterParam()

      let url

      // generate main filter if has get params in URL
      let generateMainFilter = this.generateMainFilterFromUrl(next, this)

      // generate other filter if has get params in URL
      this.generateParamsFilterFromUrl(this)

      // Generate filter URL
      url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
      )

      if(this.navTabs.deals.active) {
        if (next) this.$store.commit('setNextHeartysanDealPage', true)
        this.$store.dispatch('fetchHeartysanDeal', url).then(() => {
          this.$store.commit('setNextHeartysanDealPage', false)
        })

        this.checkCountFilter(['type', 'userName', 'sellerName'])
        return
      }

      if(this.navTabs.requests.active) {
        if (next) this.$store.commit('setNextHeartysanChatPage', true)
        this.$store.dispatch('fetchHeartysanChat', url).then(() => {
          this.$store.commit('setNextHeartysanChatPage', false)
        })

        this.checkCountFilter(['type', 'userName'])
        return
      }

      if (next) this.$store.commit('setNextHeartysanProductsPage', true)
      this.$store.dispatch('fetchHeartysanProducts', url).then(() => {
        this.$store.commit('setNextHeartysanProductsPage', false)
      })


      this.checkCountFilter(['userName', 'type', 'categoryName'])
    },

    generateFilterUrl(page, forPage) {
      let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

      if (this.navTabs.all.active || this.navTabs.draft.active || this.navTabs.archive.active) {

        if(this.navTabs.draft.active) {
          myQuery.where('status', HEARTYSAN_STATUSES.HEARTYSAN_STATUS_DRAFT.value)
        } else {
          myQuery.where('status', HEARTYSAN_STATUSES.HEARTYSAN_STATUS_ACTIVE.value)
        }

        // Check Archive
        if(this.navTabs.archive.active) {
          myQuery.where('enable', 0)
        } else {
          myQuery.where('enable', 1)
        }

        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'id',
            filterUserId: 'user_id',
            // filterCategoryId: 'category_id',
            filterCategoryId: 'parentCategoryId',
            filterName: 'name',
            filterPriceFrom: 'priceFrom',
            filterPriceTo: 'priceTo',
          },
        )

        if (!this.isAdmin) {
          myQuery.where('user_id', this.getCurrentUser?.id)
        }

      }

      if(this.navTabs.deals.active) {
        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'hearty_product_id',
              filterUserId: 'manufacturer_user_id',
              filterSellerId: 'seller_user_id',
            },
        )
      }

      if(this.navTabs.requests.active) {
        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'hearty_product_id',
              filterSellerId: 'seller_user_id',
            },

        )
      }



      // if (this.filterDate.length > 0) {
      //   let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')
      //
      //   myQuery.whereIn(
      //     'betweenCreatedAt',
      //     date
      //   )
      // }

      return this.generateGetParamsFromQuery(myQuery, forPage, page)
    },

  }

}
</script>

<style scoped>

</style>
