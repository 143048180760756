<template>
  <div class="fragment">
    <template v-if="$store.getters.getHeartysanChat.length > 0 && $store.getters.getHeartysanChatLoading !== true">
      <div class="custom-row unidentified-payments-table">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getHeartysanChat"
             :key="index">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
<!--                <div class="case-cart__img-block">-->
<!--                  <div class="case-cart__img">-->
<!--                    <img v-if="item.imageBase64" :src="'data:image/png;base64,' + item.imageBase64" alt="img">-->
<!--&lt;!&ndash;                    <img v-else-if="item.etsy_listing_images" :src="item.etsy_listing_images.split(';')[0]" alt="img">&ndash;&gt;-->
<!--                    <img v-else src="/img/finance-group/list-no-image.svg" alt="img">-->
<!--                  </div>-->
<!--                  <div class="case-cart__number-block">-->
<!--&lt;!&ndash;                  <span class="case-cart__number-i" v-if="item.small_images_file.length > 1">&ndash;&gt;-->
<!--&lt;!&ndash;                    +{{(item.small_images_file.length - 1)}}&ndash;&gt;-->
<!--&lt;!&ndash;                  </span>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
                <div class="case-cart__content-block">
<!--                  pl-0-->
                  <div class="case-cart__head ">
                    <div class="case-cart__title case-cart__title--status">
                      <TooltipTextHelper>
                        <template slot="text">
                          <div class="case-cart__content--description">{{item.product.name}}</div>
                        </template>
                        <template slot="paragraph">
                          {{item.product.name}}
                        </template>
                      </TooltipTextHelper>
                    </div>
                  </div>
                  <div class="case-cart__body ">
                    <div class="case-cart__row custom-row">
                      <TableUserNameRow
                              v-if="isAdmin"
                              :item="item"
                              :colWidth="'custom-col--66'"
                      />
                      <div class="case-cart__col custom-col custom-col--33">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_id'])"></div>
                          {{$t('common_id.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          {{ item.product.id }}
                        </div>
                      </div>
                      <div class="case-cart__col custom-col custom-col--33">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['heartysan_price'])"></div>
                          {{$t('heartysan_price.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          <template v-if="item.deal.length > 0 && item.deal[0].status === 'active'">
                            ${{item.deal[0].deal_price}}
                          </template>
                          <template v-else>
                            ${{item.product.price}}
                          </template>
                        </div>
                      </div>
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['heartysan_quantity'])"></div>
                          {{$t('heartysan_quantity.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          {{item.product.production_terms}}
                        </div>
                      </div>
                      <div class="case-cart__col custom-col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['heartysan_description'])"></div>
                          {{$t('heartysan_description.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          <TooltipTextHelper>
                            <template slot="text">
                              <div class="case-cart__content--description">{{item.product.description}}</div>
                            </template>
                            <template slot="paragraph">
                              {{item.product.description}}
                            </template>
                          </TooltipTextHelper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['heartysan_goToChat'])"></div>
                <div class="case-cart__btn ml-auto">

                </div>
                <div class="case-cart__btn ml-auto"
                     v-if="!isAdmin">
                  <router-link :to="$store.getters.GET_PATHS.heartysanChat + `?chatId=${item.id}&productId=${item.product.id}`">
                    <CaseCartButton
                            :value="$t('heartysan_goToChat.localization_value.value')"
                            :ico="true"
                            :typeIco="'edit'"
                    >
                    </CaseCartButton>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getHeartysanChatCommonList.next_page_url !== null && $store.getters.getHeartysanChat.length > 0"
                  @click.native="$emit('showMore')"
                  v-bind:class="{'disabled-btn' : $store.getters.getNextHeartysanChatPage}"
                  :count="$store.getters.getHeartysanChatCommonList.total - $store.getters.getHeartysanChatForPage < $store.getters.getHeartysanChatForPage ?
                  $store.getters.getHeartysanChatCommonList.total - $store.getters.getHeartysanChatForPage:
                  $store.getters.getHeartysanChatForPage"
          />
        </div>
      </div>

    </template>

    <template v-if="$store.getters.getHeartysanChatLoading === false && $store.getters.getHeartysanChat.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

  </div>
</template>

<script>
  import CaseCartButton from "../../../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import ShowMore from "../../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "../../../../../../../../coreComponents/NoResult/NoResult";
  import TableUserNameRow from "../../../../../../../../coreComponents/TableComponents/TableUserNameRow/TableUserNameRow";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";


  export default {
    name: "HeartysanManufacturerChatsTable",
    components: {
      TooltipTextHelper,
      TableUserNameRow,
      NoResult,
      ShowMore,
      CaseCartButton,
    },

    props: {
      countFilterParams: Number,
    },

    data() {
      return {
        isModalLinkEmailPopup: false,
        link: '',
      }
    },

    methods: {



    }
  }
</script>

<style scoped>
  .unidentified-payments-table {
    padding-top: 24px;
  }

  .case-cart__content > span, .case-cart__content > .site-link{
    white-space: nowrap;
  }

  .case-cart__content--description{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
</style>
