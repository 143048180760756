<template>
  <div class="fragment">
    <template
        v-if="$store.getters.getHeartysanProducts.length > 0 && $store.getters.getHeartysanProductsLoading !== true">
      <div class="custom-row table-top-padding">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getHeartysanProducts"
             :key="index">
          <div class="case-cart">
            <div class="case-cart__inner" v-bind:class="{draft: item.status === HEARTYSAN_STATUSES.HEARTYSAN_STATUS_DRAFT.value}">
              <div class="case-cart__columns">
                <div class="case-cart__img-block">
                  <div class="case-cart__img">
                    <img v-if="item.imageBase64" :src="'data:image/png;base64,' + item.imageBase64" alt="img">
                    <img v-else src="/img/finance-group/list-no-image.svg" alt="img">
                  </div>
                  <div class="case-cart__number-block">
                  </div>
                </div>
                <div class="case-cart__content-block">
                  <div class="case-cart__head pl-0">
                    <router-link class="case-cart__title case-cart__title--status"
                                 :to="$store.getters.GET_PATHS.heartysanManufacturerListings + '/' + item.id">
                      <TooltipTextHelper>
                        <template slot="text">
                          <div class="case-cart__content--description">{{item.name}}</div>
                        </template>
                        <template slot="paragraph">
                          {{item.name}}
                        </template>
                      </TooltipTextHelper>
                    </router-link>
                  </div>
                  <div class="case-cart__body pl-0">
                    <div class="case-cart__row custom-row">
                      <!--                      <TableUserNameRow-->
                      <!--                              v-if="isAdmin"-->
                      <!--                              :item="item"-->
                      <!--                              :colWidth="'custom-col&#45;&#45;66'"-->
                      <!--                      />-->
                      <div class="case-cart__col custom-col custom-col--33">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_id'])"></div>
                          {{ $t('common_id.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          {{ item.id }}
                        </div>
                      </div>
                      <div class="case-cart__col custom-col custom-col--33">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['heartysan_price'])"></div>
                          {{ $t('heartysan_price.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          ${{ item.price }}
                        </div>
                      </div>
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['heartysan_quantity'])"></div>
                          {{ $t('heartysan_quantity.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          {{ item.production_terms }}
                        </div>
                      </div>
                      <div class="case-cart__col custom-col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['heartysan_description'])"></div>
                          {{ $t('heartysan_description.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <TooltipTextHelper>
                            <template slot="text">
                              <div class="case-cart__content--description">{{item.description}}</div>
                            </template>
                            <template slot="paragraph">
                              {{item.description}}
                            </template>
                          </TooltipTextHelper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['heartysan_toArchive', 'common_edit'])"></div>
                <div class="case-cart__btn ml-auto"
                     v-if="!isAdmin && item.status !== HEARTYSAN_STATUSES.HEARTYSAN_STATUS_DRAFT.value">
                  <CaseCartButton
                      v-if="item.enable"
                      class="secondary-brown"
                      :value="$t('heartysan_toArchive.localization_value.value')"
                      :ico="true"
                      :typeIco="'archive-brown'"
                      @click.native="inArchive(item.id)"
                  >
                  </CaseCartButton>
                  <CaseCartButton
                      v-else
                      class="secondary-brown"
                      :value="$t('heartysan_toUnArchive.localization_value.value')"
                      :ico="true"
                      :typeIco="'archive-brown'"
                      @click.native="inUnArchive(item.id)"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn ml-auto"
                     v-if="!isAdmin">
                  <router-link :to="$store.getters.GET_PATHS.heartysanListings + '/edit/' + item.id">
                    <CaseCartButton
                        :value="$t('common_edit.localization_value.value')"
                        :ico="true"
                        :typeIco="'edit'"
                    >
                    </CaseCartButton>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getHeartysanProductsCommonList.next_page_url !== null && $store.getters.getHeartysanProducts.length > 0"
              @click.native="$emit('showMore')"
              :count="$store.getters.getHeartysanProductsCommonList.total - $store.getters.getHeartysanProductsForPage < $store.getters.getHeartysanProductsForPage ?
                  $store.getters.getHeartysanProductsCommonList.total - $store.getters.getHeartysanProductsForPage:
                  $store.getters.getHeartysanProductsForPage"
          />
        </div>
      </div>

    </template>

    <template
        v-if="$store.getters.getHeartysanProductsLoading === false && $store.getters.getHeartysanProducts.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

  </div>
</template>

<script>
import CaseCartButton from "../../../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
import ShowMore from "../../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import NoResult from "../../../../../../../../coreComponents/NoResult/NoResult";
import {HEARTYSAN_STATUSES} from "@/staticData/staticVariables";
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
// import TableUserNameRow from "../../../../../../../../coreComponents/TableComponents/TableUserNameRow/TableUserNameRow";


export default {
  name: "HeartysanManufacturerListingsTable",
  components: {
    TooltipTextHelper,
    // TableUserNameRow,
    NoResult,
    ShowMore,
    CaseCartButton,
  },

  props: {
    countFilterParams: Number,
  },

  data() {
    return {
      isModalLinkEmailPopup: false,
      link: '',
      HEARTYSAN_STATUSES: HEARTYSAN_STATUSES,
    }
  },

  methods: {



    inArchive(id) {

      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_yes',
        no: 'common_confirmNo'
      }


      let confirm = () => {

        this.$store.dispatch('heartysanProductsInArchive', id).then((response) => {

          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.$emit('reload')
              this.openNotify('success', 'common_notificationRecordChanged')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    inUnArchive(id) {

      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_yes',
        no: 'common_confirmNo'
      }


      let confirm = () => {

        this.$store.dispatch('heartysanProductsInUnArchive', id).then((response) => {

          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.$emit('reload')
              this.openNotify('success', 'common_notificationRecordChanged')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

  }
}
</script>

<style scoped lang="scss">
@import "../../../../../../../../../scss/colors";

.table-top-padding {
  padding-top: 24px;
}

.case-cart__content > span, .case-cart__content > .site-link {
  white-space: nowrap;
}

.case-cart__inner.draft {
  background: $draft;
}

.case-cart__content--description{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

</style>
